<template lang="pug">
  header#header.lb-header
    b-navbar#mainbar.p-0(ref='headerMainbar' toggleable='lg' type='light' variant='primary')
      b-navbar-brand#mainbar-brand.d-lg-none.d-inline-flex.align-items-center.m-0.p-0.pl-3.mr-auto(:to="{ name: brandLink }")
        figure.figure.brand.d-flex.mb-0
          .wc-i.wc-symbol.rounded
        .wc-connect.text-uppercase.text-white.ml-2
          | {{ $wc.conf.base.name }}

      b-navbar-nav#main-menu.navbar-item-btn.d-none.d-lg-flex.justify-content-start.flex-row.px-4(
        v-if='isAMT'
        :class='mainMenuClass')
        b-nav-item.d-none.d-lg-flex(
          link-classes='d-flex align-items-center px-2 px-lg-3 btn btn-white'
          :to="{ name: 'manager' }")
          | {{ $t('shared.menus.manager') }}

      b-navbar-nav#main-tools.navbar-item-btn.ml-auto.d-flex.justify-content-end.flex-row.px-3
        <!-- Notifications -->
        b-nav-item(link-classes='d-flex align-items-center px-2 px-lg-3')
          i.fal.fa-btn.fa-fw.fa-25w.fa-bell.text-white

        <!-- Account -->
        header-account.d-none.d-lg-flex

        <!-- Aside menu toggler -->
        aside-menu-toggle.d-lg-none
</template>

<script>
import AsideMenuToggle from '@components/layouts/base/aside/MenuToggle'
import HeaderAccount from '@components/layouts/base/header/Account'

export default {
  name: 'lb-header',
  components: {
    AsideMenuToggle,
    HeaderAccount,
  },
  computed: {
    isAMT() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['auth/isAMT']
    },
    mainMenuClass() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['ui/isAsideOpen'] ? '' : 'aside-closed'
    },
    brandLink() {
      return this.isAMT ? 'manager' : 'dashboard'
    },
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';
.lb-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 95;
  #main-menu {
    margin-left: $wc-sidebar-width;
    transition: $wc-transition-all-cubic;
    transform-origin: center left;
    &.aside-closed {
      margin-left: $wc-sidebar-min-width;
    }
  }
}
</style>
