<template lang="pug">
  b-list-group.lb-aside-menu(
    role='tablist'
    tag='ul')
    b-list-group-item.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      :active="exact('')"
      @click="handleClick({ name: 'dashboard' })")
      i.fal.fa-fw.fa-25w.fa-15x.fa-tachometer.flex-shrink-0
      span.pl-3
        | {{ $t('shared.menus.dashboard') }}

    b-list-group-item.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      :active="starts('/machines')"
      @click="handleClick({ name: 'machines' })")
      i.fal.fa-fw.fa-25w.fa-15x.fa-conveyor-belt-alt.flex-shrink-0
      span.pl-3
        | {{ $t('activerecord.models.machine.other') }}

    b-list-group-item.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      :active="starts('/organizations')"
      @click="handleClick({ name: 'organizations' })")
      i.fal.fa-fw.fa-25w.fa-15x.fa-building.flex-shrink-0
      span.pl-3
        | {{ $t('activerecord.models.organization.other') }}

    b-dropdown-divider.my-3

    b-list-group-item.accordion-toggle.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      v-b-toggle.main-identity-tools
      :active='activeCurrentAvatar')
      .fa-fw.fa-25w.flex-shrink-0
        current-avatar(
          classes='wc-bubble-xxs wc-bubble-br shadow-sm'
          :model="$root.$store.getters['auth/currentModelType']")
      span.pl-3
        | {{ $t('activerecord.models.identity.one') }}
      i.ml-auto.caret.fal.fa-angle-down

    aside-organization(v-if='isOrg')
    aside-account(v-else)

    b-list-group-item.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      @click.stop.prevent='onLogout')
      i.fal.fa-fw.fa-25w.fa-15x.fa-unlock.flex-shrink-0
      span.pl-3
        | {{ $t('session.sign_out') }}

    b-dropdown-divider.my-3.list-unstyled

    wc-language-switch.list-unstyled(
      toggle-class='list-group-item-action d-flex align-items-center px-4 py-3 text-body'
      dropup
      right
      no-caret)
</template>

<script>
import CurrentAvatar from '@components/layouts/shared/CurrentAvatar'
import AsideAccount from '@components/layouts/base/aside/Account'
import AsideOrganization from '@components/layouts/base/aside/Organization'
import WcLanguageSwitch from '@components/shared/WcLanguageSwitch'
import { Menu } from '@common/menu'

export default {
  name: 'lb-aside-menu',
  mixins: [Menu],
  components: {
    CurrentAvatar,
    AsideAccount,
    AsideOrganization,
    WcLanguageSwitch,
  },
  computed: {
    activeCurrentAvatar() {
      return this.starts('/organization/') || this.starts('/account/')
    },
    isOrg() {
      return this.$root.$store.getters['auth/isOrganization']
    },
  },
  methods: {
    handleClick(params) {
      if (this.$store.getters['ui/isMediumDevice']) this.$store.dispatch('ui/toggleAside')
      this.$router.push(params)
    },
    onLogout() {
      this.$auth.signOut({
        extraQueryParams: { ui_locales: this.$wc.shared.locale },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-base {
  #aside,
  &.l-aside-closed #aside:hover {
    #sidebar-menu {
      .list-group {
        > .list-group-item {
          font-weight: $font-weight-bold;
        }
        .list-group-item {
          position: relative;
          border: 0;
          border-radius: 0;
          padding: 1rem 1.5rem;
          white-space: nowrap;
          color: $body-color;
          &.active span,
          &:hover span,
          i {
            color: $wc-primarydark;
          }
          .caret {
            display: block;
          }
        }
        .list-group-item.active,
        .list-group-item-action:hover,
        .list-group-item-action:focus {
          background-color: lighten($wc-primarylight, 20%);
        }
        .list-group-item-action > span,
        .accordion-collapse {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          transition: $wc-transition-all-ease;
        }
        .accordion-collapse {
          max-height: 50rem;
        }
        .accordion-toggle.collapsed .caret:before {
          content: '\F105';
        }
      }
    }
  }

  &.l-aside-closed #aside {
    #sidebar-menu {
      .list-group {
        .list-group-item-action > span,
        .accordion-collapse {
          width: 0;
        }
        .accordion-collapse {
          max-height: 0;
        }
        > .list-group-item .caret {
          display: none;
        }
      }
    }
  }
}
</style>
