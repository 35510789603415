<template lang="pug">
  b-avatar.ls-current-avatar.wc-bubble(
    :class='classes'
    :src='portraitUrl'
    :to='link'
    variant='white'
    alt='')
    template(v-if='!portraitUrl' #default)
      .wc-bubble-content.bg-loader(v-if='!panelShow')
        .i-wrapper
          i.fas.fa-fw.fa-spinner-third.fa-spin.text-white
      .wc-bubble-content.wc-bubble-linked.bg-primary(v-if='panelShow && !portraitUrl')
        .i-wrapper
          i.fal.fa-fw.text-white(:class='iconClass')
</template>

<script>
import { Panel } from '@common/panel'
import emitter from '@common/emitter'

export default {
  name: 'ls-current-avatar',
  mixins: [Panel],
  props: {
    model: String,
    variant: {
      type: String,
      default: 'xsmall',
    },
    classes: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: null,
    },
    data: Object,
    show: Boolean,
  },
  computed: {
    portraitUrl() {
      return this.$getDeep(this.panel.data, `attributes.portrait_${this.variant}_url`)
    },
    iconClass() {
      return `${this.model === 'organization' ? 'fa-building' : 'fa-user'} ${this.iconClasses}`
    },
  },
  methods: {
    cachePayloadFunc() {
      return this.model === 'organization'
        ? { organization_id: this.$store.getters['auth/currentOrganization'].sub }
        : { account_id: this.$store.getters['auth/currentAccount'].sub }
    },
    async apiCallback(callback) {
      if (callback === 'fill-error' && this.model === 'organization') {
        await this.$store.dispatch('auth/removeCurrentOrganization')
        this.$router.push({ path: '/' })
      }
    },
  },
  watch: {
    model(value) {
      this.cacheType = `${value}/getPanel`
    },
    panel() {
      this.panel.rs.identity = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.identity.data.type'),
        this.$getDeep(this.panel.data, 'relationships.identity.data.id')
      )
      this.panel.rs.profile = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.profile.data.type'),
        this.$getDeep(this.panel.data, 'relationships.profile.data.id')
      )
      this.$emit('update:data', this.panel)
    },
    panelShow() {
      this.$emit('update:show', this.panelShow)
    },
  },
  data() {
    return {
      cacheType: `${this.model}/getPanel`,
      cacheOptions: { timeout: this.$wc.conf.cache.session },
      skipNotifications: true,
    }
  },
  mounted() {
    emitter.on('ls-current-avatar:panel-reload', () => {
      this.panelReload()
    })
  },
  destroyed() {
    emitter.off('ls-current-avatar:panel-reload')
  },
}
</script>
