<template lang="pug">
  b-nav-item.lb-footer-identity-tools-switchable-item.py-1(
    link-classes='d-flex align-items-center text-body px-0'
    :disabled='itemActive'
    @click.stop.prevent='itemSwitch')
    b-avatar.wc-bubble.wc-bubble-xxs.wc-bubble-br.shadow.mr-3.text-white(
      :src='item.attributes.portrait_xsmall_url'
      variant='white'
      alt='')
      template(v-if='!item.attributes.portrait_xsmall_url' #default)
        .wc-bubble-content.wc-bubble-linked.bg-primary
          .i-wrapper
            i.fal.fa-fw.text-white(:class='itemIconClass')
    .identity.small
      .name(:class='itemActiveClass')
        | {{ item.attributes.name }}
      .username.text-muted
        | {{ item.attributes.username }}
</template>

<script>
export default {
  name: 'lb-footer-identity-tools-switchable-item',
  props: {
    item: Object,
    isOrganization: Boolean,
  },
  computed: {
    itemIconClass() {
      return this.isOrganization ? 'fa-building' : 'fa-user'
    },
    itemActive() {
      return (!this.isOrganization && !this.$store.getters['auth/isOrganization']) ||
        this.$store.getters['auth/currentOrganization'].sid === this.item.attributes.sid
        ? true
        : false
    },
    itemActiveClass() {
      return this.itemActive ? 'text-primary font-weight-bold' : ''
    },
  },
  methods: {
    async itemSwitch() {
      this.isOrganization
        ? await this.$store.dispatch('auth/setCurrentOrganization', this.item.attributes.sid)
        : await this.$store.dispatch('auth/removeCurrentOrganization')
      this.$router.push({ path: '/', query: { s: this.item.attributes.sid } })
    },
  },
}
</script>
