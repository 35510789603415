<template lang="pug">
  b-collapse#main-identity-tools.lb-aside-account.accordion-collapse(
    role='tabpanel'
    accordion='sidebar-accordion'
    :visible="starts('/account/')")
    b-list-group-item(
      tag='a'
      :active="starts('/account/edit')"
      @click="handleClick({ name: 'account-edit' })")
      i.fal.fa-btn.fa-fw.fa-key
      span.pl-3
        | {{ $t('account.menus.my_account') }}

    b-list-group-item(
      tag='a'
      :active="starts('/account/profile')"
      @click="handleClick({ name: 'account-profile-edit' })")
      i.fal.fa-btn.fa-fw.fa-user
      span.pl-3
        | {{ $t('account/profile.menus.my_profile') }}

    b-list-group-item(
      tag='a'
      :active="starts('/account/contact')"
      @click="handleClick({ name: 'account-contact-edit' })")
      i.fal.fa-btn.fa-fw.fa-comment-dots
      span.pl-3
        | {{ $t('contact.menus.my_contact') }}

    b-list-group-item(
      tag='a'
      :active="starts('/account/address')"
      @click="handleClick({ name: 'account-address-edit' })")
      i.fal.fa-btn.fa-fw.fa-map-marker
      span.pl-3
        | {{ $t('address.menus.my_address') }}
</template>

<script>
import { Menu } from '@common/menu'

export default {
  name: 'lb-aside-account',
  mixins: [Menu],
  methods: {
    handleClick(params) {
      if (this.$store.getters['ui/isMediumDevice']) this.$store.dispatch('ui/toggleAside')
      this.$router.push(params)
    },
  },
}
</script>
