<template lang="pug">
  b-sidebar#footer-identity-tools.lb-footer-identity-tools.wc-bottombar(
    width='100%'
    bg-variant='white'
    header-class='pt-2 pb-0'
    backdrop-variant='transparent'
    backdrop)
    template(#header-close)
      i.far.fa-horizontal-rule
    b-container.px-3.pt-2.pb-4
      b-nav(v-if='panelShow' vertical)
        template(v-for='(item, index) in panel.data')
          identity-tools-switchable-item(
            :item='item'
            :is-organization="item.attributes.class_snake === 'organization'")
        .small(v-if='panel.data.length === 1')
          i.fal.fa-fw.fa-info-circle.text-primary
          |  {{ $t('account.no_switchable_organizations') }}
      template(v-else v-for='(item, index) in 3')
        wc-loaders-heading-avatar.py-2(style-class='avatar-xxs')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import IdentityToolsSwitchableItem from '@components/layouts/base/footer/IdentityToolsSwitchableItem'

export default {
  name: 'lb-footer-identity-tools',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    IdentityToolsSwitchableItem,
  },
  methods: {
    cachePayloadFunc() {
      return { account_id: this.$store.getters['auth/currentAccount'].sub }
    },
  },
  data() {
    return {
      cacheType: 'account/getSwitchables',
      cacheOptions: { timeout: this.$wc.conf.cache.session },
      skipNotifications: true,
    }
  },
}
</script>
