<template lang="pug">
  footer#footer.lb-footer
    b-navbar#footerbar.py-0.d-lg-none(type='light' fixed='bottom' variant='white')

      <!-- Footer Tools -->
      b-navbar-nav#footer-tools.w-100(justified)
        b-nav-item(
          link-classes='d-flex justify-content-center align-items-center'
          :to="{ name: 'dashboard' }")
          i.fal.fa-btn.fa-fw.fa-tachometer
        b-nav-item(
          link-classes='d-flex justify-content-center align-items-center'
          :to="{ name: 'machines' }")
          i.fal.fa-btn.fa-fw.fa-conveyor-belt-alt

        <!-- Account or organization -->
        b-nav-item(
          link-classes='d-flex justify-content-center align-items-center'
          :active='isIdentity'
          @click='onIdentityClick'
          @contextmenu='onIdentityLongTap')
          current-avatar(
            classes='wc-bubble-xxs wc-bubble-bl shadow'
            :model="$store.getters['auth/currentModelType']")

    identity-tools
</template>

<script>
import CurrentAvatar from '@components/layouts/shared/CurrentAvatar'
import IdentityTools from '@components/layouts/base/footer/IdentityTools'

export default {
  name: 'lb-footer',
  components: {
    CurrentAvatar,
    IdentityTools,
  },
  computed: {
    isIdentity() {
      return this.$store.getters['auth/isOrganization']
        ? this.$route.name === 'organization-edit'
        : this.$route.name === 'account-edit'
    },
  },
  methods: {
    onIdentityClick() {
      return this.$store.getters['auth/isOrganization']
        ? this.$router.push({ name: 'organization-edit' })
        : this.$router.push({ name: 'account-edit' })
    },
    onIdentityLongTap(event) {
      event.preventDefault()
      this.$root.$emit('bv::toggle::collapse', 'footer-identity-tools')
    },
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-base {
  #footer {
    #footerbar {
      min-height: $wc-footer-min-height;
      box-shadow: $box-shadow-top-sm;
      .nav-link {
        font-size: $font-size-lg;
        height: $wc-footer-min-height;
      }
    }
  }
}
</style>
