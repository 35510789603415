<template lang="pug">
  .lb-aside-organization
    b-collapse#main-identity-tools.accordion-collapse(
      role='tabpanel'
      accordion='sidebar-accordion'
      :visible="starts('/organization/')")
      b-list-group-item(
        tag='a'
        :active="starts('/organization/edit')"
        @click="handleClick({ name: 'organization-edit' })")
        i.fal.fa-btn.fa-fw.fa-key
        span.pl-3
          | {{ $t('organization.menus.my_organization') }}

      b-list-group-item(
        tag='a'
        :active="starts('/organization/profile')"
        @click="handleClick({ name: 'organization-profile-edit' })")
        i.fal.fa-btn.fa-fw.fa-user
        span.pl-3
          | {{ $t('organization/profile.menus.my_profile') }}

      b-list-group-item(
        tag='a'
        :active="starts('/organization/contact')"
        @click="handleClick({ name: 'organization-contact-edit' })")
        i.fal.fa-btn.fa-fw.fa-comment-dots
        span.pl-3
          | {{ $t('contact.menus.my_contact') }}

      b-list-group-item(
        tag='a'
        :active="starts('/organization/address')"
        @click="handleClick({ name: 'organization-address-edit' })")
        i.fal.fa-btn.fa-fw.fa-map-marker
        span.pl-3
          | {{ $t('address.menus.my_address') }}

    b-list-group-item.d-flex.align-items-center.px-4(
      tag='a'
      role='tab'
      @click.stop.prevent='onSwitchToAccount')
      i.fal.fa-fw.fa-25w.fa-15x.fa-repeat.flex-shrink-0
      span.pl-3
        | {{ $t('organization.menus.switch_to_account') }}
</template>

<script>
import { Menu } from '@common/menu'

export default {
  name: 'lb-aside-organization',
  mixins: [Menu],
  computed: {
    isHome() {
      return this.$route.name === 'dashboard'
    },
  },
  methods: {
    handleClick(params) {
      if (this.$store.getters['ui/isMediumDevice']) this.$store.dispatch('ui/toggleAside')
      if (params) this.$router.push(params)
    },
    async onSwitchToAccount() {
      await this.$store.dispatch('auth/removeCurrentOrganization')
      this.handleClick()
      this.isHome
        ? this.$router.push({
            path: '/',
            query: { s: this.$store.getters['auth/currentAccount'].sid },
          })
        : this.$router.push({ path: '/' })
    },
  },
}
</script>
