<template lang="pug">
  aside#aside.lb-aside
    b-navbar#sidebar.flex-column.align-items-stretch.p-0(type='light' variant='white')

      #sidebar-header.d-none.d-lg-block
        .d-flex.justify-content-between.align-items-center.bg-primarydark.px-4
          b-navbar-brand#sidebar-brand.d-inline-flex.align-items-center.m-0.p-0(
            :to="{ name: 'dashboard' }")
            figure.figure.brand.d-flex.mb-0
              .wc-i.wc-symbol.rounded
            .wc-connect.text-uppercase.text-white.ml-2
              | {{ $wc.conf.base.name }}

          aside-menu-toggle

      #sidebar-menu.flex-grow-1
        aside-menu

      #sidebar-footer.d-flex.flex-column.justify-content-end.pt-5.pb-3
        //-.d-flex.px-3.justify-content-center
          b-link.text-muted.small.mr-3(
            @click="handleClick({ name: 'about' })")
            | {{ $t('shared.menus.about') }}
          b-link.text-muted.small.mr-3(
            @click="handleClick({ name: 'terms' })")
            | {{ $t('shared.menus.terms') }}

        .d-flex.px-3.justify-content-center
          b-link.text-muted.small.mr-3(
            @click.stop.prevent='onCookies')
            | {{ $t('shared.menus.cookies') }}
          //-b-link.text-muted.small.mr-3(
            @click="handleClick({ name: 'privacy' })")
            | {{ $t('shared.menus.privacy') }}

        .d-flex.mt-2.px-3.justify-content-center
          span.text-muted.small
            | &copy
            |  windegger.eu
</template>

<script>
import AsideMenuToggle from '@components/layouts/base/aside/MenuToggle'
import AsideMenu from '@components/layouts/base/aside/Menu'

export default {
  name: 'lb-aside',
  components: {
    AsideMenuToggle,
    AsideMenu,
  },
  methods: {
    handleClick(params) {
      if (this.$store.getters['ui/isMediumDevice']) this.$store.dispatch('ui/toggleAside')
      this.$router.push(params)
    },
    onCookies() {
      this.$root.$refs.container.$refs.wcCookieLaw.onSettingsShow()
    },
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-base {
  #aside,
  #sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: $wc-sidebar-height;
    @include media-breakpoint-down(md) {
      min-height: $wc-sidebar-height-mobile;
    }
  }
  #aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: $wc-sidebar-width;
    width: $wc-sidebar-width;
    margin-left: -$wc-sidebar-width;
    box-shadow: 0 0 1.5rem rgba($wc-black, 0.25);
    transition: $wc-transition-all-cubic;
    transform-origin: center left;
    z-index: 1000;
    #sidebar-menu {
      a {
        cursor: pointer;
      }
    }
    #sidebar-brand .wc-i {
      transition: $wc-transition-all-ease;
    }
    #sidebar-footer,
    #sidebar-footer > div > * {
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &.l-aside-closed #sidebar-brand .wc-connect {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    &.l-aside-closed #aside:hover {
      width: $wc-sidebar-width;
    }
    &.l-aside-closed #aside:not(:hover) {
      width: $wc-sidebar-min-width;
      #sidebar-brand .wc-i,
      #sidebar-footer {
        width: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    #aside {
      top: $wc-mainbar-height-mobile;
      #sidebar-footer {
        margin-bottom: $wc-footer-min-height;
      }
    }
    &.l-aside-closed #aside {
      width: 0;
    }
  }
}
</style>
